  .checkbox {
    opacity: 0;
    position: absolute;
  }

  .label {
    width: 30px;
    height: 20px;
    background-color: #111;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    cursor: pointer;
  }

  .ball {
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    left: -4px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }

  .checkbox:checked + .label .ball {
    transform: translateX(18px);
  }

#dark {
  

}
