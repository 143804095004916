@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --background-light: url("./assets/bgIMG.png");
  --background-dark: url("./assets/bgIMGdark.png");
  --primary-color: #edf2f8;
  --primary-dark-color: #2A0944;
  --secondary-dark-color: #FEC260;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --purple-color: #2A0944;
  --light-purple-color: #3B185F;
  --accent-color: #A12568;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}